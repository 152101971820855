import React, {useState} from 'react'
import {navigate} from 'gatsby'
import {
	Dropdown
} from 'semantic-ui-react'
import * as styles from './styles/productSum.module.css'

export default ({
	items, //all the variants except the current item
	currentItem, //current item in the variants
	productName,
	promoItem
}) => {
	const [currentValue, setCurrentValue] = useState(undefined)
	const options = items.map(({name, url, meta})=>{
		return {
			key:url,
			text:`${name} (Php${meta.display_price.with_tax.formatted})`,
			value:name,
		}
	})

	return (

		<Dropdown
			selection
			options={options}
			className={promoItem ? styles.dropdownHighlight+" "+styles.form: styles.form}
			onChange={(event, data)=>{
				const chosen = items.filter(({name})=>{
					return data.value===name
				})
				setCurrentValue(data.value)
				let index
				for(let i=0; i<options.length;i++) {
					if(options[i].value===data.value) {
						index=i+1
						break;
					}
				}


				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					'event':'productClick',
					'ecommerce': {
						'click': {
							'actionField': {'list': promoItem ? `Promo Dropdown-${productName}` : `Variant Dropdown-${productName}`},
							'products': [{
								'name':chosen[0].slug,
								'id':chosen[0].sku,
								'price':parseFloat(chosen[0].meta.display_price.with_tax.amount),
								'position': index,
								'item_id': chosen[0].sku,
								'item_name': chosen[0].slug,
								'currency': "PHP",
								'index': index,
								'item_list_id': promoItem ? `Promo Dropdown-${productName}` : `Variant Dropdown-${productName}`,
								'item_list_name': promoItem ? `Promo Dropdown-${productName}` : `Variant Dropdown-${productName}`,
								'quantity': 1
							}]
						}
					}
				});

				navigate(`/${chosen[0].url}/`)
			}}
			selectOnBlur={false}
			selectOnNavigation={false}
			text={currentValue===undefined ? promoItem ? "Click here for Available Promos" : "Other Size/Color/Flavor":null}
		>
		</Dropdown>
	)
}

