import React from 'react'
import {Link} from 'gatsby'
import {Header, Image} from 'semantic-ui-react'
import * as styles from './styles/productAttribute.module.css'
export default ({productSection, productName}) =>  {

	const mapProductSections = ({type, title, content, items, internalLink, externalLink, imgurl}, depth=0)=> {

		let titleClassName = depth===0 ? styles.header:`${styles.header} ${styles.noMargin}`;
		let preSectionContent= (
			<React.Fragment>
				{title && <Header className={titleClassName}>{title}</Header> }
			</React.Fragment>
		);
		let sectionContent;

		//just in case type wasn't properly assigned a value
		if(type===undefined || type===null) {
			return ""
		}

		if(type==="html") {
			sectionContent = (
				<div
					className={styles.text}
					dangerouslySetInnerHTML={{
						__html: content
					}}
				/>
			)

		}else if(type==="text") {
			if(internalLink!== undefined && internalLink !==null) {
				return (
					<React.Fragment>
						{preSectionContent}
						<p className={styles.text}>
							<Link to={internalLink}>{content}</Link>
						</p>
					</React.Fragment>
				)
			}
			if(externalLink!== undefined && externalLink !==null) {
				return (
					<React.Fragment>
						{preSectionContent}
						<p className={styles.text}>
							<a
								href={externalLink}
								target="_blank"
								rel="noopener noreferrer"
							>
								{content}
							</a>
						</p>
					</React.Fragment>
				)
			}

			sectionContent = (
				<p className={styles.text}>
					{content}
				</p>
			)
		}else if(type==="image-full") {
			sectionContent = (
				<Image src={imgurl} alt={content} fluid/>
			)
		}else if(type==="specification") {
			let value = content
			if(internalLink!== undefined && internalLink !==null) {
				value = (
					<Link to={internalLink}>{content}</Link>
				)
			}
			if(externalLink!== undefined && externalLink !==null) {
				value = (
					<a
						href={externalLink}
						target="_blank"
						rel="noopener noreferrer"
					>
						{content}
					</a>
				)
			}
			return (
				<div className={styles.text}>
					<strong>{title}</strong>:{value}
				</div>
			)
		} else if(type.split("-")[0]==="list") {
			let listContent = (
				<React.Fragment>
					{items.map((section, innerIndex)=>{
						if(depth===4 && section.type.split("-")[0]==="list" ) {
							return "";
						}
						return (
							<li key={`${section.type}-${section.content}-${section.title}-${section.imgurl}-depth${depth+1}`} className={styles.listMargin}>{mapProductSections(section, depth+1)}</li>
						)
					})}
				</React.Fragment>
			)
			if(type.split("-")[1]==="numbered") {
				sectionContent = (
					<ol>
						{listContent}
					</ol>
				)
			}else if(type.split("-")[1]==="bullet") {
				sectionContent = (
					<ul>
						{listContent}
					</ul>
				)
			}
			preSectionContent = (
				<React.Fragment>
					{title && <Header className={titleClassName}>{title}</Header> }
					<div className={styles.text}>
						{content}
					</div>
				</React.Fragment>
			)
			if(internalLink!== undefined && internalLink !==null) {
				preSectionContent = (
					<React.Fragment>
						{title && <Header className={titleClassName}>{title}</Header> }

						<div className={styles.text}>
							<Link to={internalLink}>{content}</Link>
						</div>

					</React.Fragment>
				)
			}
			if(externalLink!== undefined && externalLink !==null) {
				preSectionContent = (
					<React.Fragment>
						{title && <Header className={titleClassName}>{title}</Header> }

						<div className={styles.text}>
							<a
								href={externalLink}
								target="_blank"
								rel="noopener noreferrer"
							>
								{content}
							</a>
						</div>

					</React.Fragment>
				)
			}
			return (
				<React.Fragment>
					{preSectionContent}
					{sectionContent}
				</React.Fragment>
			)
		}



		if(internalLink!== undefined && internalLink !==null) {
			return (
				<React.Fragment>
					{preSectionContent}
					<Link to={internalLink}>
						{sectionContent}
					</Link>
				</React.Fragment>
			)
		}else if(externalLink!== undefined && externalLink !==null) {
			return (
				<React.Fragment>
					{preSectionContent}
					<a
						href={externalLink}
						target="_blank"
						rel="noopener noreferrer"
					>
						{sectionContent}
					</a>
				</React.Fragment>
			)
		}else {
			return (
				<React.Fragment>
					{preSectionContent}
					{sectionContent}
				</React.Fragment>
			)
		}

	}

	return (
		<div>
			{productSection.map((section, index,)=>{
				if(section.content===productName) {
					return ""
				}
				return (
					<div className={styles.sectionMargin} key={`${section.type}-${section.content}-${section.title}-${section.imgurl}-depth0`}>
						{mapProductSections(section, 0)}
					</div>
				)
			})}
		</div>
	)
}