import React from 'react'
import {graphql, Link, navigate} from 'gatsby'
import BlogList from '../components/BlogList'
import SEO from '../components/SEO'
import ProductSummary from '../components/ProductSummary'
import Layout from '../components/Layout'
import ProductList from '../components/ProductList'
import ProductAttributes from '../components/ProductAttributes'
import SubCategoryListSq from '../components/SubCategoryList/SubCategorySquare'
import SubCategoryListImage from '../components/SubCategoryList/SubCategoryImage'
import RetryButton from '../components/RetryButton'
//import ApiConfig from '../../config/api-config'

import LoginSubmissionForm from '../components/Forms/loginSubmissionForm'


import {
	Container,
	Header,
	Message,
} from 'semantic-ui-react'
import * as styles from './styles/template.module.css'
import {
	mobileMaxWidth,
	resizeTimer,
	tabletMaxWidth
} from '../globalVar'



const Negosyo = require('../../lib/negosyo')



class ProductPageTemplate extends React.PureComponent {
	state = {
		product:this.props.data.negosyoNow.viewProduct,
		productLoading:true,
		username: "",
		signedIn: false,
		error:false,
		recipes:[],
		relatedProduct:[],
		category:[],
		business:[],
		shown:undefined,
		timeOut1:undefined,
		productSection:this.props.data.negosyoNow.viewProduct.sections,
		productSectionError:false
	}

	componentWillUnmount() {
		clearTimeout(this.state.timeOut1);
		window.removeEventListener('resize', this.resizeTimerFunc);
	}

	handler = () => {
		//computer
		if (window.innerWidth > tabletMaxWidth ) {
			this.setState({
				shown: 5,
			})
		} //tablet
		else if (window.innerWidth > mobileMaxWidth ) {
			this.setState({
				shown : 5,
			})
		} else {  //mobile
			this.setState({
				shown : 2,
			})
		}
	}

	resizeTimerFunc = () => {
		clearTimeout(this.state.timeOut1);
		const temp = setTimeout(this.handler, resizeTimer);
		this.setState({
			timeOut1:temp
		})
	}
	componentDidMount() {
		this.handleUserLogIn();
		/*
		// Causes issues if product data is cached
		if (this.state.product.exclusive.length > 0) {
			this.handleUserLogIn();
		} else {
			this.fetchData();
		}
		*/
		this.handler();
		window.addEventListener('resize', this.resizeTimerFunc);

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			'event': 'productDetails',
			'ecommerce': {
				'detail': {
					'products': [
						{
							'name': this.state.product.name,
							'id': this.state.product.sku,
							'price': this.state.product.meta.display_price.with_tax.amount,
							'brand': this.state.product.brand ? this.state.product.brand:"Generic",
							'dimension1': this.state.product.available===true ? "InStock":"OutOfStock",
							'item_category5': this.state.product.available===true ? "InStock":"OutOfStock",
							'item_brand': this.state.product.brand ? this.state.product.brand:"Generic",
							'item_id': this.state.product.sku,
							'item_name': this.state.product.name,
							'currency': "PHP",
							'quantity': 1
						}
					]
				}
			}
		});

	}

	handleLoginSignIn = (username) => {
		this.handleUserLogIn();
	}

	handleLoginSucceed = (name, contact, email, username) => {
		this.handleUserLogIn();
	}

	handleUserLogIn = () => {
		Negosyo.isLoggedIn()
		.then((isLoggedIn)=>{
			if(isLoggedIn) {
				Negosyo.getCurrentUserDetails()
				.then((user)=>{
					this.setState({
						username: user.username,
						signedIn:true
					}, function() {
						 this.fetchData()
					})
				}).catch((err)=>{

				})
			} else {
				this.fetchData();
			}
		})
	}

	relatedProducts = () => {
		document.getElementById('page-related').scrollIntoView({
			behavior: 'smooth'
		});
	}

	seeMore = () => {
		// From Product Summary
		if(this.props.location.state) {

			if(this.props.location.state.cameFrom) {
				const page = this.props.location.state.cameFrom.split('-')[0]
				//not sure if order page is back button or home page or something else
				if( this.props.location.state.cameFrom && page!=="cart") {
					window.history.back();
				}else {
					navigate('/');
				}
			}else {
				navigate('/');
			}

		}else {
			navigate('/');
		}
	}

	fetchData = (retry=false) => {
		const {
			url
		} = this.props.pageContext
		if (this.state.username.length > 0) {
			// TODO Better solution
			(async () => {
				const response = await Negosyo.getProductSectionExclusive(url, this.state.username);
				this.updateProduct(response)
			})();
		} else {
			Negosyo.getProductSection(url)
			.then((data)=>{
				this.updateProduct(data)
			}).catch((err)=>{
				this.setState({
					error:true,
					productLoading:false
				})
			})
		}
	}

	updateProduct = (data) => {
		this.setState({
			product:data,
			productLoading:false,
			recipes:data.recipe,
			relatedProduct:data.related,
			category:data.category,
			business:data.business,
			productSection:data.sections
		})
	}



	render() {
		const {
			product,
			error,
			productLoading,
			recipes,
			shown,
			relatedProduct,
			category,
			business,
			productSection
		} = this.state
		const {
			location
		} = this.props
		const {
			fetchData
		} = this
		//https://developers.google.com/search/docs/data-types/product
		var structuredData = {
			"@context": "https://schema.org/",
			"@type": "Product",
			"name": product.name,
			"productID": product.sku,
			"sku": product.sku,
			"image": [
				product.mainImageHref,
			],
			"description": product.summary,
			//"review": {},
			//"aggregateRating": {},
			"offers": {
				"@type": "Offer",
				"url": location.href,
				"priceCurrency": product.meta.display_price.with_tax.currency.toUpperCase(),
				"price": product.meta.display_price.with_tax.amount,
				"itemCondition": "https://schema.org/NewCondition",
				"availability": product.available===true ? "https://schema.org/InStock":"https://schema.org/OutOfStock",
			}
		}
		if (product.brand) {
			structuredData.brand = {
					"@type": "Brand",
					"name": product.brand
			};
		}

		return (

			<Layout location={location} backButton={true}>
				<Container text className={styles.container}>
					<SEO
						canonicalpath={"/"+product.url}
						title={product===undefined ? "Product Page":product.name}
						image={product.mainImageHref}
						twitterCard={"summary"}
						description={product.summary}
						structuredData={structuredData}
					/>
					{error===true  && (
						<RetryButton
							retryFunc={()=>{
								this.setState({
									error:false,
									productLoading:true
								})
								fetchData(true)
							}}
							errorMessage={"Unable to load Product Information"}
						/>
					)}
					{( product.available===false && productLoading===false ) && (
						<Message warning className={styles.msg}>
							{ product.exclusive.length > 0 ?
								<>
									<Message.Header>
										Product is exclusive to selected members only
									</Message.Header>
									{
										this.state.signedIn ?
											<>
												We're sorry but you're not eligible or the offer is no longer available.<br/>
												You may also try to <Link to={"/request-product/"} state={{productName:product.name, requestNotes: "Request Access to exclusive item"}}>Request for Exclusive Products</Link>
											</>
										:
											<LoginSubmissionForm
												noUserInfo={true}
												handleSignIn={this.handleLoginSignIn}
											/>
									}
								</>
								:
								<>
									<Message.Header>
										Product no longer available.
									</Message.Header>
									Want this item back? Let us know through our <Link to={"/request-product/"} state={{productName:product.name}}>Request a Product</Link> form
								</>
							}
							<>
								<hr/>
								<button className={"fluid ui button "+styles.linkButton} onClick={()=>this.relatedProducts()}> View Similar Products </button>
							</>
						</Message>
					)}
					<ProductSummary
						{...product}
						location={location}
						disabled={ error || ( product.available===false && productLoading===false )}
						loading={productLoading}
					/>
					<ProductAttributes productSection={productSection} productName={product.name} />
				</Container>
				<span id="page-related" className={styles.anchor}/>
				<Container className={styles.container}>
					{recipes.length> 0 && (
						<React.Fragment>
							<Header className={`${styles.header}`}>
								Recipes that use {product.name}:
							</Header>
							<BlogList
								items={recipes}
								limitSummary={true}
							/>
						</React.Fragment>
					)}

					{relatedProduct.length> 0 && (
						<React.Fragment>
							<Header className={`${styles.header}`}>
								Others also bought:
							</Header>
							<ProductList
								products={relatedProduct}
								shown={shown}
								grid={16}
								cameFrom={`product-${product.name}`}
								listName={`Related Products-${product.name}`}
							/>
						</React.Fragment>
					)}

					{category.length> 0 && (
						<React.Fragment>
							<Header className={`${styles.header}`}>
								See related items:
							</Header>
							<SubCategoryListImage
							  relatedcategorylist={category}
							  shown={shown}
							/>
						</React.Fragment>
					)}

					{business.length> 0 && (
						<React.Fragment>
							<Header className={`${styles.header}`}>
								Businesses that might interest you:
							</Header>
							<SubCategoryListSq
								relatedcategorylist={business}
								shown={shown}
								reverse={true}
								contentClassName={styles.BusinessListContent}
								headerClassname={styles.BusinessListHeader}
							/>
						</React.Fragment>
					)}
					<div className={"ui segment "+styles.container}>
						<button className={"fluid ui button "+styles.seemoreButton} onClick={()=>this.seeMore()}> Go Back </button>
					</div>
				</Container>
			</Layout>
		)
	}
}

export default ProductPageTemplate

export const data = graphql`
	query ProductQuery($url:String!) {
		negosyoNow {
			viewProduct( url: $url ) {
				id
				name
				brand
				summary
				url
				mainImageHref
				description
				meta {
					display_price {
						with_tax {
							formatted
							currency
							amount
						}
					}
				}
				slug
				organizationName
				organizationUrl
				orgsku
				sku
				available
				images {
					thumbnail
					url
				}
				variant {
					name
					sku
					url
					slug
					meta	{
						display_price {
							with_tax {
								amount
								currency
								formatted
							}
						}
					}
				}
				sections {
					content
					externalLink
					imgurl
					internalLink
					items {
						type
						title
						internalLink
						imgurl
						externalLink
						content
					}
					type
					title
				}
				exclusive {
					code
					price
				}
				options {
					key
					name
				}
			}
		}
	}
`


