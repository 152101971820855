import React, {useState} from 'react'
import {navigate} from 'gatsby'
import {
	Dropdown
} from 'semantic-ui-react'
import * as styles from './styles/productSum.module.css'

export default ({
    updateOption,
	items, //all the variants except the current item
}) => {
	const [currentValue, setCurrentValue] = useState(undefined)
	const options = items.map(({name, key})=>{
		return {
			key:key,
			text:name,
			value:name,
		}
	})

	return (

		<Dropdown
			selection
			options={options}
			className={styles.form}
			onChange={(event, data)=>{
				const chosen = items.filter(({name})=>{
					return data.value===name
				})
				setCurrentValue(data.value);
				let index
				for(let i=0; i<options.length;i++) {
					if(options[i].value===data.value) {
						index=i+1
						break;
					}
				}
                updateOption(data.value);
			}}
			selectOnBlur={false}
			selectOnNavigation={false}
			text={currentValue===undefined ? "Choose Option":null}
		>
		</Dropdown>
	)
}

