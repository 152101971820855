/*image carousel with most of its functionality for the multiple image in product page, not sure yet if I properly made it so it can be used more */

//header is required and needs to be unique or else
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import {
	Button,
	Header,
	Image
} from 'semantic-ui-react'
import {Link} from 'gatsby'
import {
		CarouselProvider,
		Slide,
		Slider,
		ButtonBack,
		ButtonNext,
} from "pure-react-carousel";
import 'pure-react-carousel/dist/react-carousel.es.css';
import * as styles from './styles/carousel.module.css'
import {
	mobileMaxWidth,
	resizeTimer,
	tabletMaxWidth
} from '../../globalVar'




class ImageCarousel extends Component {
	state= {
		shown: this.props.initialShown,
		height: 1.2,
		timeOut1:undefined,
		timeOut2:undefined,
		windowWidth:undefined,
	}

	componentDidMount() {
		this._isMounted = true;
		this.handler();
		window.addEventListener('resize', this.resizeFunc)

	}

	resizeFunc = () => {
		clearTimeout(this.state.timeOut1)
		const temp = setTimeout(this.handler, resizeTimer);
		if(this._isMounted) {
			this.setState({
				timeOut1:temp
			})
		}

	}



	/* a function that uses both requestAnimationFrame and setTimeout to make sure the paint is done
	 * before calling the function to resize the cards and carousel Provider
	 * setTimeout to clear the callstack and requestAnimationFrame to make sure the dom is rendered
	 * before doing the resizing of cards/carousel.
	 */
	reqAnimationFrame = (time) => {
		clearTimeout(this.state.timeOut2)
		const callback = this.findCards
		const timeOut = setTimeout( () => {
			window.requestAnimationFrame(callback)
		},time)
		//clears the Timeout to make less repaints/re render for the screen
		if(this._isMounted) {
			this.setState({timeOut2:timeOut})
		}
	}

	componentDidUpdate(prevProps) {

		if(prevProps.items!==this.props.items) {
			/*so to resize the card sizes and carousel overall height since the items
			 *could have different heights for each card not to mention description/name
			 */
			this.reqAnimationFrame(150);
		}
	}

	//resizes the cards to have uniform height and also changes the CarouselProvider height to fit the cards
	findCards = () => {
		if(this._isMounted) {
			const component = ReactDOM.findDOMNode(this)
			const images = component.getElementsByClassName(this.props.imageClassName)
			let finished = true
			for(let i=0; i<images.length;i++) {
				if(images[i].complete===false) {
					finished=false
					break;
				}
			}
			if(images.length===0) {
				this.reqAnimationFrame(150)
			}else if(finished===false) {
				this.reqAnimationFrame(150);
			}
			/*checks just to make sure the component is mounted before doing component related stuff
			 *since this is called by a timeout an async function and component can unmount anytime
			 */
			let diff = 0

			if(this._isMounted) {
				for( let i=0; i<images.length;i++) {


					if( !this._isMounted ) {
						break;
					}
					/*+10 for just an offset since we have top:5px and an extra 5px is added here just to be sure borders don't get cut
					 *the 100/92 is for the offsetWidth is for the card className to get the full width of the Slide component
					 */
					if( diff < ( (images[i].offsetHeight+10) / ( images[i].offsetWidth* (100/92) ) ) ) {
						diff = (images[i].offsetHeight+10)/ ( images[i].offsetWidth* (100/92) ) // for the carousel Provider height
					}

				}
			}



			/*set the heights of the carousel with an offset to make sure carousel has a gap till the next component
			 *is also needed to make the component repaint showing the new height.
			 */
			if(this._isMounted) {
				this.setState({ height:diff})
			}

		}
	}



	componentWillUnmount() {
		this._isMounted = false;
		window.removeEventListener('resize', this.resizeFunc)
		clearTimeout(this.state.timeOut1)
		clearTimeout(this.state.timeOut2)

	}


	/* for changing how many are shown in the carousel when browser is resized could be replaced by the
	 * alternative suggested by the semantic ui react team on their upgrade guide from responsive component
	 * https://github.com/Semantic-Org/Semantic-UI-React/pull/4008
	 */
	handler = () => {
		//computer
		if(window.innerWidth > tabletMaxWidth ) {
			if(this._isMounted) {
				this.setState({
					shown : this.props.computerShown,
				}, ()=>{
					this.reqAnimationFrame(0)
				})
			}

		} //tablet
		else if(window.innerWidth > mobileMaxWidth ){
			if(this._isMounted) {
				this.setState({
					shown:this.props.tabletShown,
				}, ()=>{
					this.reqAnimationFrame(0)
				})
			}

		} //mobile
		else {
			if(this._isMounted) {
				this.setState({
					shown : this.props.mobileShown,
				}, ()=>{
					this.reqAnimationFrame(0)
				})
			}

		}
	}

	render() {
		const {
			header,
			showHeader,
			items,
			error,
			retryFunc,
			onImageClick,
			onImageHover,
			imagesAsLinks,
			imageClassName,
			selectedImageClassName,
			selectedImage,
			navButtonStyle,
			squareAsSrc
		} = this.props
		const {shown, height} = this.state
		const imageProps = {}


		return (
			<div className={styles.container}>
				{showHeader && (
					<Header className={styles.header}>
						{header}
					</Header>
				)}

				{error===true ? (
					<div className={`${styles.text} ${styles.centerText}`}>
						<Button
							icon="redo"
							size="big"
							className={styles.iconButton}
							onClick={()=>{
								retryFunc()
							}}
						/>
						<br/><br/>
						Try Again
					</div>
				):(
					<CarouselProvider
						naturalSlideWidth={1}
						naturalSlideHeight={height}
						totalSlides={items.length}
						visibleSlides={shown}
						infinite={true}
						dragEnabled={items.length > shown}
					>
						<Slider className={styles.slider} >
							{ items.map( ({ url, squareImageHref, mainImageHref}, index) => {
								if(imagesAsLinks) {
									imageProps.to=url
									imageProps.as=Link
								}
								if(onImageClick) {
									imageProps.onClick=()=>{
										onImageClick({squareImageHref, mainImageHref})
									}
								}
								if(onImageHover) {
									imageProps.onMouseOver=()=>{
										onImageHover({squareImageHref, mainImageHref})
									}
								}
								let extraClassImage = ""
								if( selectedImage === mainImageHref) {
									if(selectedImageClassName!==undefined) {
										extraClassImage = selectedImageClassName
									}
								}
								return (
									<Slide
										index={index}
										key={squareImageHref || mainImageHref}
									>
										<Image
											src={squareAsSrc ? squareImageHref:mainImageHref}
											className={`${imageClassName} ${extraClassImage}`}
											{...imageProps}
										/>
									</Slide>
								)
							})}
						</Slider>
						{shown < items.length && (
							<React.Fragment>
								<ButtonBack
									className={navButtonStyle ? `${styles.buttonBack} ${navButtonStyle}`:`${styles.buttonBack} ${styles.navButton}`}
								>
									{`<`}
								</ButtonBack>
								<ButtonNext
									className={navButtonStyle ? `${styles.buttonNext} ${navButtonStyle}`:`${styles.buttonNext} ${styles.navButton}`}
								>
									{`>`}
								</ButtonNext>
							</React.Fragment>
						)}
					</CarouselProvider>
				)}
			</div>
		)
	}
}
export default ImageCarousel

ImageCarousel.defaultProps = {
	showHeader:true,
	imagesAsLinks:true,
	initialShown:4,
	computerShown:5,
	tabletShown:4,
	mobileShown:3,
	imageClassName:styles.justImage,
	squareAsSrc:false
}