import React, {Component} from 'react'
import {Input} from 'semantic-ui-react'
import CartContext from '../Context/CartContext'
import * as styles from './styles/addToCart.module.css'
import {
	addProductToCartColor,
} from '../../globalVar'

const NegosyoNow = require('../../../lib/negosyo')

class AddToCart extends Component  {
	state = {
		loading:false,
		error:'',
		quantity:1,
		timeOutRef:null,
	}
	static contextType = CartContext

	componentWillUnmount() {
		clearTimeout(this.state.timeOutRef)
	}

	validate = quantity => {
		let error
		const re = /^[0-9\b]+$/

		if (!quantity) {
			error = "Can't be blank"
			return error
		}
		if (!re.test(quantity)) {
			error = 'Please enter a valid positive number'
		}else if(parseInt(quantity) < 1 ) {
			error = "Please enter a number higher than 0";
		}


		return error
	}

	retrySubmit = () => {

		const {
			quantity
		} = this.state
		const {
			productId,
			note,
			showPopUp
		} = this.props
		const cartId = localStorage.getItem('mcart')
		if(cartId) {
			NegosyoNow.addToCart(cartId, productId, quantity, note)
			.then(({data, meta}) => {
				let total = 0
				for(let i = 0; i<data.length; i++) {
					total += data[i].data.reduce((a, c) => a + c.quantity, 0)
				}
				let {updateCartCount} = this.context
				updateCartCount(total, cartId)
				this.setState({
					loading:false,
					quantity:quantity
				})
				showPopUp(quantity)
			}).catch(err => {
				this.setState({
					error:('Error in Adding Product to cart'),
					loading:false
				})
			})
		}else {

			let {gettingCartId} = this.context
			if(gettingCartId===false && localStorage.getItem('mcart')===null) {
				this.setState({
					error:('Error in Adding Product to cart'),
					loading:false
				})
			}else {
				const timeOut = setTimeout( () => {
					this.retrySubmit()
				},100)
				this.setState({
					timeOutRef:timeOut
				})
			}

		}
	}

	handleSubmit = (event) => {
		event.preventDefault()
		const cartId = localStorage.getItem('mcart')
		const {
			quantity
		} = this.state
		const {
			validate
		} = this
		const {
			productId,
			note,
			showPopUp
		} = this.props
		let error = validate(quantity);

		if (note === undefined) {
			error = "Please Choose Option"
		}
		this.setState({
			error:error
		})
		if (!error) {
			this.setState({
				loading:true
			}, ()=>{
				if(cartId) {
					NegosyoNow.addToCart(cartId, productId, quantity, note)
					.then(({data, meta}) => {
						let total = 0
						for(let i = 0; i<data.length; i++) {
							total += data[i].data.reduce((a, c) => a + c.quantity, 0)
						}
						const {updateCartCount} = this.context
						updateCartCount(total, cartId)
						this.setState({
							loading:false,
							quantity:quantity
						})
						showPopUp(quantity)
					})
					.catch(err => {
						this.setState({
							error:('Error in Adding Product to cart'),
							loading:false
						})
					})
				}else {
					const {gettingCartId, initiateRetry} = this.context
					if(gettingCartId===false ) {
						//cart provider isn't trying to get a new cart ID,
						const newCartId = localStorage.getItem('mcart')
						if( !newCartId ) {
							//no cart id in localstorage, so request cart provider to try to get a new one
							initiateRetry()
							.then(()=>{
								this.retrySubmit()
							})

						}else {
							this.handleSubmit()//has cartId in local storage now
						}

					}else {
						//still trying to get Cart Id from api or getting cart count from api so wait for to resolve
						const timeOut = setTimeout(()=>{
							this.handleSubmit()
						}, 200)
						this.setState({
							timeOutRef:timeOut
						})
					}

				}
			})

		}
	}

	handleChange = ({target: {value}}) =>  {
		this.setState({
			quantity:value
		})
	}

	render() {
		const {
			quantity,
			error,
			loading
		} = this.state
		const {
			disabled,
			loadingProduct
		} = this.props
		const {
			handleChange,
			handleSubmit
		} = this
		return (
			<>
				<Input
					input={{
						className:styles.form
					}}
					type="number"
					placeholder="Quantity"
					value={quantity}
					min={1}
					step={1}
					error={!!error}
					onChange={handleChange}
					action={{
						color: addProductToCartColor,
						content: loadingProduct ? "Loading...":'Add to Cart',
						icon: {
							name: loadingProduct ? "":'plus cart'
						},
						onClick: loadingProduct ? ()=>{}:handleSubmit,
						loading,
						disabled: loading || disabled,
						name:"addToCartButton"
					}}
					name={"addToCartQuantity"}
				/>
				{error &&
					<div className={`${styles.message}  ${styles.errorMessage}`} name={"AddToCartErrorMsg"}>
						{error}
					</div>
				}

			</>
		)
	}
}

export default AddToCart

