// extracted by mini-css-extract-plugin
export var centerText = "productSum-module--centerText--21c21";
export var desktopOnlyMod = "productSum-module--desktopOnlyMod--ca2b1";
export var disabledButton = "productSum-module--disabledButton--fb477";
export var dropdown = "productSum-module--dropdown--29ee0";
export var dropdownHighlight = "productSum-module--dropdownHighlight--0c6e7";
export var form = "productSum-module--form--36486";
export var header = "productSum-module--header--40332";
export var imageCarouselClassName = "productSum-module--imageCarouselClassName--14b31";
export var imgCarouselDesktopNav = "productSum-module--imgCarouselDesktopNav--45ab2";
export var imgCarouselMobileNav = "productSum-module--imgCarouselMobileNav--49afa";
export var imgCarouselNav = "productSum-module--imgCarouselNav--80c5c";
export var item = "productSum-module--item--bb736";
export var link = "productSum-module--link--9997a";
export var mobileOnlyMod = "productSum-module--mobileOnlyMod--6a915";
export var proceedButton = "productSum-module--proceedButton--506e7";
export var selectedImage = "productSum-module--selectedImage--f2324";
export var text = "productSum-module--text--c2535";