
/*this isn't used anymore, this was used to show the category above the products in category page
 *but since limiting the category to 1 line made it impossible to read the name I changed it so that carousel
 *shows the category instead so we can show more than 7 categories without it being too squished
 */


import React, {useState, useEffect} from 'react'
import {Image, Card} from 'semantic-ui-react'
import {Link} from 'gatsby'
import * as styles from './styles/subCategoryList.module.css'


const mapCategoriesToItems = (relatedcategorylist, reverse, contentClassName, headerClassname) => {
	return relatedcategorylist.map(({name, squareImageHref, url }) => {
		return (
			<Card
				as={Link}
				to={`/${url}/`}
				key={url}
			>
				{reverse===false && (
					<Image fluid alt={name} src={squareImageHref}/>
				)}
				<Card.Content className={contentClassName!==undefined ? `${contentClassName} ${styles.card}`:styles.card}>
					<Card.Header className={headerClassname!==undefined ? headerClassname:""}>
						{name}
					</Card.Header>
				</Card.Content>
				{reverse===true && (
					<Image fluid alt={name} src={squareImageHref}/>
				)}
			</Card>
		)
	})
}

const SubCategorySquareComponent = ({relatedcategorylist, shown, reverse=false, contentClassName, headerClassname}) => {
	const [list, setList] = useState([])
	useEffect(()=>
	{
		//filters categorylist so that it won't show those without a url and a square image
		const filteredCategoryList = relatedcategorylist.filter((category)=>category.url)
		const completelyfilteredlist = filteredCategoryList.filter((category)=>category.squareImageHref)
		// 20201101 - Removed slice, causing some categories to be missing
		//setList(completelyfilteredlist.slice(0, 10))
		setList(completelyfilteredlist)


		/* only added relatedcategorylist to remove the
		 * react-hooks/exhaustive deps warning though it shouldn't
		 * break without it since relatedcategorylist is a prop from
		 * build time from gatsby node
		 */
	}, [relatedcategorylist])

	//how many will be shown
	let tempShown = shown ? shown : 5

	const length = (list.length > 5 && !(shown)) ? list.length : tempShown
	return (

		<Card.Group
			itemsPerRow={length}
		>
			{mapCategoriesToItems(list, reverse, contentClassName, headerClassname)}
		</Card.Group>
	)
}

export default SubCategorySquareComponent
